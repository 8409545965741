<!--
================================================================================
  Template (HTML)
================================================================================
-->

<template>
  <v-card>
    <v-card-title>
      <div
        v-t="'order.response.title'"
        class="text-h5"/>
    </v-card-title>
    <v-card-text>
      <v-progress-circular
        v-if="waiting"
        indeterminate/>
      <payload
        v-if="response"
        v-model="response"
        readonly/>
      <span
        v-else-if="!waiting"
        v-t="'order.response.noOrder'"/>
    </v-card-text>
  </v-card>
</template>

<!--
================================================================================
  Logic (JavaScript)
================================================================================
-->

<script>
  import Payload from '@/app/core/components/CodeEditor/Payload'

  export default {
    name: 'OrderResponse',

    components: {
      Payload
    },

    props: {
      waiting: {
        type: Boolean,
        required: true
      },
      response: {
        type: String,
        required: true
      }
    }
  }
</script>
