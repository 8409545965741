<template>
  <code-editor
    v-model="model"
    :options="options"
    :readonly="readonly"
    :lines="lines"
    :auto-grow="autoGrow"
    :placeholder="placeholder"
    @input="$emit ('input', $event)"/>
</template>

<script>
  import CodeEditorMixin from './CodeEditorMixin'

  export default {
    name: 'Payload',

    mixins: [CodeEditorMixin],

    data () {
      return {
        options: {
          mode: 'properties'
        }
      }
    },

    async mounted () {
      await import ('codemirror/mode/properties/properties')
    }
  }
</script>
