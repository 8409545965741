<messages>["./Contact"]</messages>

<template>
  <base-layout mw2>
    <v-col lg="5" cols="12">
      <v-card>
        <form
          novalidate
          @submit.prevent="submit()">
          <v-card-title primary-title>
            <div>
              <div v-t="'inquire.contactData'" class="text-h5"/>
              <div
                v-t="'inquire.subtitle'"
                class="cgwng-subheading"/>
            </div>
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <registry-select
                  v-model="registry"
                  :error-messages="validationErrors (
                    'registry', {
                      required: 'inquire.registryRequired'
                    })"
                  :label="$t ('inquire.registry')"
                  @blur="$v.registry.$touch"/>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model.trim="contactHandle"
                  clearable
                  :label="$t ('inquire.contactHandle')"
                  :error-messages="validationErrors (
                    'contactHandle', {
                      required: 'inquire.handleRequired'
                    })"
                  @blur="$v.contactHandle.$touch"/>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer/>
            <v-btn
              v-t="'general.button.reset'" text
              :disabled="isLoading"
              @click="reset"/>
            <v-btn
              v-t="'general.button.submit'" color="primary"
              :loading="isLoading"
              type="submit"/>
          </v-card-actions>
        </form>
      </v-card>
    </v-col>

    <v-slide-y-transition>
      <v-col
        v-if="result || isLoading"
        lg="7" cols="12">
        <v-alert
          v-if="error"
          v-t="'label.error'"
          type="error"/>
        <order-response
          :waiting="isLoading"
          :response="result"/>
      </v-col>
    </v-slide-y-transition>
  </base-layout>
</template>

<script>
  import {mapActions} from 'vuex'
  import {required} from 'vuelidate/lib/validators'

  import validationMixins from '@/app/core/mixins/ValidationHelper'

  import BaseLayout from '@/app/core/components/BaseLayout'
  import OrderResponse from '@/app/pages/Order/components/OrderResponse'
  import RegistrySelect from '@/app/core/components/RegistrySelect'

  const PROCESSING_ERROR = 'error/server/processing'
  const PAYLOAD_PROPERTY = 'payload'

  export default {
    name: 'ContactInquire',

    components: {
      BaseLayout,
      OrderResponse,
      RegistrySelect
    },

    mixins: [validationMixins],

    validations: {
      contactHandle: {
        required
      },
      registry: {
        required
      }
    },

    data () {
      return {
        contactHandle: '',
        result: '',
        registry: null,
        isLoading: false,
        error: false
      }
    },

    methods: {
      ...mapActions ({
        fetchData: 'request/fetchData'
      }),

      reset () {
        this.contactHandle = null
        this.registry = null
        this.$v.$reset ()
      },

      setError (error) {
        this.result = error
        this.error = true
      },

      submit () {
        if (!this.$v.$invalid) {
          this.isLoading = true

          this.fetchData ({
            op: 'contact/inquire',
            params: {
              registry: this.registry,
              handle: this.contactHandle
            },
            cb: data => {
              this.result = data.payloadResult
            },
            cbError: (data, next) => {
              if (data.result.find (
                (e) => e.code === PROCESSING_ERROR && e.params?.property === PAYLOAD_PROPERTY)
              ) {
                this.setError (data.errorData)
              } else {
                next (data)
              }
            },
            cbFinal: () => {
              this.isLoading = false
            }
          })
        } else {
          this.$v.$touch ()
        }
      }
    }
  }
</script>
